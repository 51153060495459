import { template as template_bea3f216384d46e88c83374a6f3894b5 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_bea3f216384d46e88c83374a6f3894b5(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
