import { template as template_b2a9ea983ce742e2ae58216d697a9f7c } from "@ember/template-compiler";
const SidebarSectionMessage = template_b2a9ea983ce742e2ae58216d697a9f7c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
