import { template as template_5c2bbe83e0e04941ae97033c37ebbc89 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_5c2bbe83e0e04941ae97033c37ebbc89(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
