import { template as template_7a365b9d89ea4868bf1185ea7b699efe } from "@ember/template-compiler";
const FKControlMenuContainer = template_7a365b9d89ea4868bf1185ea7b699efe(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
