import { template as template_4bfffaae9d5d4c72851fb12dd1409615 } from "@ember/template-compiler";
const FKLabel = template_4bfffaae9d5d4c72851fb12dd1409615(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
