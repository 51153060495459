import { template as template_59207dfd9dc441a88149e914054d9c90 } from "@ember/template-compiler";
const FKText = template_59207dfd9dc441a88149e914054d9c90(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
